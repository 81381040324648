html {
  scroll-behavior: smooth;
}

body {
  margin: 0px;
  width: 100%;
  overflow-x: hidden;
}

hr {
  width: 50px;
  display: block;
  unicode-bidi: isolate;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
  margin-inline-start: auto;
  margin-inline-end: auto;
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  border: 0;
  border-top: 3px solid #ef343b;
}

a {
  color: #ef343b !important;
  transition: all 0.2s !important;
  text-decoration: none !important;
  background-color: transparent !important;
  cursor: pointer;
}

.grecaptcha-badge {
  display: none !important;
}
