.alpha-right-enter {
  opacity: 0;
  transform: translateX(-100%);
}
.alpha-right-enter-active {
  opacity: 1;
  transform: translateX(0%);
}
.alpha-right-exit {
  opacity: 1;
  transform: translateX(0%);
}
.alpha-right-exit-active {
  opacity: 0;
  transform: translateX(100%);
}

.alpha-left-enter {
  opacity: 0;
  transform: translateX(100%);
}
.alpha-left-enter-active {
  opacity: 1;
  transform: translateX(0%);
}
.alpha-left-exit {
  opacity: 1;
  transform: translateX(0%);
}
.alpha-left-exit-active {
  opacity: 0;
  transform: translateX(-100%);
}
.alpha-left-enter-active,
.alpha-left-exit-active,
.alpha-right-enter-active,
.alpha-right-exit-active {
  transition: opacity 500ms, transform 500ms;
}
